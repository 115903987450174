@import '@/styles/common.scss';

#routeDiv {
  width: 100%;
  height: 250px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.playWnd {
  width: 99.8%; /*播放容器的宽和高设定*/
  height: 87.5%;
  border: 1px solid red;
  display: flex;
}

/* .operate {
  padding-top: 10px;
}

.operate::after {
  content: "";
  display: block;
  clear: both;
} */

.module {
  float: left;
  width: 340px;
  /*min-height: 320px;*/
  margin-left: 16px;
  padding: 16px 8px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
}

.module .item {
  margin-bottom: 4px;
}

.module input[type="text"] {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
  width: 150px;
  min-height: 20px;
}

.module .btn {
  min-width: 80px;
  min-height: 24px;
  margin-top: 100px;
  margin-left: 80px;
}
/deep/ .el-dialog__body {
  height: 70vh;
  overflow: auto;
  background-color: white;
}
/deep/ .el-dialog__header {
  background-color: white;
}
/deep/ .el-dialog__title {
  color: black;
}
